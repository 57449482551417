import { Add, BusinessOutlined, CreditCard, SwapHoriz } from "@mui/icons-material";
import {
  IconButton,
  SvgIcon,
  Popover,
  Typography,
  Tooltip,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
} from "@mui/material";
import { Stack, Box, Theme, useMediaQuery } from "@mui/system";
import { ChevronDown, Menu01, Users01 } from "@untitled-ui/icons-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Logo } from "../../components/logo";
import {
  useOrganization,
  SelectOrganizationModal,
} from "../../contexts/organization/OrganizationContext";
import { organizationSlice } from "../../features/organization/organizationSlice";
import { useBillingStatus } from "../../hooks/use-billing";
import { SIDE_NAV_WIDTH } from "../../layouts/dashboard/vertical-layout/side-nav";
import { RouterPaths } from "src/main";
import { useNavigate } from "@tanstack/react-router";
import { CreateOrganizationDialog } from "../organization/CreateOrganizationDialog";

interface DashboardHeaderProps {
  onMobileNav: (() => void) | undefined;
}

interface IOptionsMenu {
  title: string;
  path: RouterPaths;
  icon: JSX.Element;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ onMobileNav }) => {
  const navigate = useNavigate();
  const { data } = useBillingStatus();
  const { selectedOrganization } = useOrganization();
  const orgPhoto = useSelector(organizationSlice.selectors.organizationPhoto);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const parentRef = React.useRef(null); // Cria a referência
  const [openChangeOrg, setOpenChangeOrg] = useState<boolean>(false);
  const [openCreateOrg, setOpenCreateOrg] = useState<boolean>(false);
  const canChangeOrganization = useSelector(organizationSlice.selectors.canChangeOrganization);

  const handleClick = () => {
    setAnchorEl(parentRef.current); // Define o elemento âncora para a Box pai
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleNavigate(path: RouterPaths) {
    handleClose();
    navigate({
      to: path,
    });
  }

  const open = Boolean(anchorEl);
  const id = open ? "dashboard-popover" : undefined;
  const name = selectedOrganization?.org_name || "Nenhum workspace selecionado";
  const plan = `Plano: ${data.plan?.label}`;
  const maxWidth = SIDE_NAV_WIDTH - 115;

  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const menus: IOptionsMenu[] = [
    {
      title: "Configurações",
      path: "/organizacao/detalhes",
      icon: (
        <SvgIcon fontSize="small">
          <BusinessOutlined />
        </SvgIcon>
      ),
    },
    {
      title: "Colaboradores",
      path: "/organizacao/colaboradores",
      icon: (
        <SvgIcon fontSize="small">
          <Users01 />
        </SvgIcon>
      ),
    },
    {
      title: "Faturamento",
      path: "/organizacao/faturamento",
      icon: (
        <SvgIcon fontSize="small">
          <CreditCard />
        </SvgIcon>
      ),
    },
  ];

  const HeaderLogo = ({ size = 40 }) => (
    <>
      {orgPhoto ? (
        <Avatar
          sx={{ width: size, height: size }}
          src={orgPhoto || ""}
          alt={selectedOrganization?.org_name}
        />
      ) : (
        <Box
          sx={{
            borderColor: "var(--nav-logo-border)",
            borderRadius: 1,
            borderStyle: "solid",
            borderWidth: 1,
            display: "flex",
            height: size,
            width: size,
          }}
        >
          <Logo variant="only_icon" />
        </Box>
      )}
    </>
  );

  const HeaderDashboard = () => (
    <Stack alignItems="center" direction="row" spacing={2}>
      {!mdUp && (
        <IconButton onClick={onMobileNav}>
          <SvgIcon>
            <Menu01 />
          </SvgIcon>
        </IconButton>
      )}
      <HeaderLogo size={24} />
      <Stack alignItems="center" direction="row" spacing={2}>
        <Box width={maxWidth}>
          <Tooltip title={plan}>
            <Typography
              color="inherit"
              variant="h6"
              noWrap
              textOverflow={"ellipsis"}
              textTransform={"capitalize"}
            >
              {name}
            </Typography>
          </Tooltip>
        </Box>
        <SvgIcon sx={{ fontSize: 16 }}>
          <ChevronDown />
        </SvgIcon>
      </Stack>
    </Stack>
  );

  const HeaderPopover = () => (
    <Stack alignItems="center" direction="row" spacing={2}>
      {!mdUp && (
        <IconButton onClick={onMobileNav}>
          <SvgIcon>
            <Menu01 />
          </SvgIcon>
        </IconButton>
      )}
      <HeaderLogo />
      <Stack alignItems="center" direction="row" spacing={2}>
        <Box width={maxWidth}>
          <Tooltip title={name}>
            <Typography
              color="inherit"
              variant="h6"
              noWrap
              textOverflow={"ellipsis"}
              textTransform={"capitalize"}
            >
              {name}
            </Typography>
          </Tooltip>
          <Typography pt={0.1} color="neutral.400" variant="body2" noWrap textOverflow={"ellipsis"}>
            {plan}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );

  useEffect(() => {
    if (openChangeOrg === false) {
      handleClose();
    }
  }, [openChangeOrg]);

  return (
    <Box ref={parentRef}>
      <div
        onClick={handleClick}
        style={{
          cursor: "pointer",
          padding: 0,
          border: "none",
          background: "none",
        }}
      >
        <HeaderDashboard />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2 }}>
          <HeaderPopover />
        </Box>
        <Divider />
        <Box sx={{ p: 1 }}>
          {menus.map(({ icon, path, title }) => {
            return (
              <ListItemButton
                key={title}
                onClick={() => handleNavigate(path)}
                sx={{
                  borderRadius: 1,
                  px: 1,
                  py: 0.5,
                }}
              >
                <ListItemIcon>
                  <SvgIcon fontSize="small">{icon}</SvgIcon>
                </ListItemIcon>
                <ListItemText primary={<Typography variant="body1">{title}</Typography>} />
              </ListItemButton>
            );
          })}
        </Box>
        <Divider sx={{ my: "0 !important" }} />
        <Box sx={{ p: 1 }}>
          {/* <ListSubheader disableSticky>Configurações</ListSubheader> */}
          <Tooltip
            title={
              canChangeOrganization
                ? ""
                : "Você não possui mais de uma organização, crie uma primeiro"
            }
          >
            <ListItemButton
              disabled={!canChangeOrganization}
              onClick={() => setOpenChangeOrg(true)}
              sx={{
                borderRadius: 1,
                px: 1,
                py: 0.5,
              }}
            >
              <ListItemIcon>
                <SvgIcon fontSize="small">
                  <SwapHoriz />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary={<Typography variant="body1">Trocar organização</Typography>} />
            </ListItemButton>
          </Tooltip>

          <ListItemButton
            onClick={() => setOpenCreateOrg(true)}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <Add />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary={<Typography variant="body1">Nova organização</Typography>} />
          </ListItemButton>
        </Box>
      </Popover>
      <SelectOrganizationModal open={openChangeOrg} setOpen={setOpenChangeOrg} allowClose />
      <CreateOrganizationDialog open={openCreateOrg} onClose={() => setOpenCreateOrg(false)} />
    </Box>
  );
};

export default DashboardHeader;
