import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  Stack,
  Grid,
  TextField,
  Chip,
  Tooltip,
  Divider,
  Typography,
} from "@mui/material";
import Slider, { Settings } from "react-slick";
import {
  approvalCreateSlice,
  deleteApprovalItem,
  ISliceItemUpsertDto,
} from "src/features/approvalsCreate/approvalCreateSlice";
import { Mimetype } from "src/utils/get-mimetype";
import { useAppDispatch } from "src/app/hooks";
import toast from "react-hot-toast";
import {
  IApprovalStageCustomer,
  IStatus,
  IStatusTypeStpTypeEnum,
} from "src/services/thumbz-base-api";
import { getApprovalStatusChips } from "src/utils/status";
import { useConfirm } from "material-ui-confirm";
import { InstagramCarouselEdit } from "./instagram-carousel-edit";
import { InstagramRenderImgPreview } from "./instagram-render-img-preview";

interface InstagramCarouselCardProps {
  approvalItem: ISliceItemUpsertDto;
  onUnmergeAll: () => void;
  onUnmergeSingle: (mediaIndex: number) => void;
  enableThumbnail?: boolean;
  approvalStageCustomers: IApprovalStageCustomer[];
  apv_status: IStatus;
}

export const InstagramCarouselApprovalItemCard: React.FC<InstagramCarouselCardProps> = ({
  approvalItem,
  onUnmergeAll,
  onUnmergeSingle,
  approvalStageCustomers,
  enableThumbnail = false,
  apv_status,
}) => {
  const confirm = useConfirm();
  const dispatch = useAppDispatch();

  const [isReorderModalOpen, setReorderModalOpen] = useState<boolean>(false);

  function handleSelectToEdit(): void {
    dispatch(approvalCreateSlice.actions.selectApprovalItem(approvalItem));
  }

  const handleOpenReorderModal = () => {
    setReorderModalOpen(true);
  };

  const handleCloseReorderModal = () => {
    setReorderModalOpen(false);
  };

  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    arrows: false,
    dotsClass: "slick-dots slick-thumb",
  };

  const statusChips = getApprovalStatusChips(approvalStageCustomers);

  function handleDeleteApprovalItem(approvalItem: ISliceItemUpsertDto): void {
    confirm({
      description: "Tem certeza que deseja remover este item?",
      confirmationText: "Sim",
      cancellationText: "Não",
    }).then(() => {
      dispatch(
        deleteApprovalItem({
          item_id: approvalItem.item_id,
        }),
      );
    });
  }

  return (
    <Card sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
      <Grid container>
        <Grid item xs={4} mb={approvalItem.assets.length > 1 ? 4 : 0}>
          {approvalItem.assets.length > 1 ? (
            <Slider {...settings}>
              {approvalItem.assets.map((item, index) => (
                <div key={`media-${index}`}>
                  <InstagramRenderImgPreview
                    file={item.file}
                    mimetype={item.props?.mimetype ?? Mimetype.Image}
                    already_uploaded_url={item.already_uploaded_url}
                    showThumbnail={enableThumbnail}
                  />
                  <Stack>
                    {item.file?.name ||
                      (item.uploaded_asset?.ast_original_filename && (
                        <Typography variant="caption" color="textSecondary" align="center">
                          {item.file?.name || item.uploaded_asset?.ast_original_filename}
                        </Typography>
                      ))}
                  </Stack>
                </div>
              ))}
            </Slider>
          ) : (
            <>
              <Stack flex={1} justifyContent="center" alignItems="center">
                {approvalItem.assets.length > 0 && (
                  <InstagramRenderImgPreview
                    file={approvalItem.assets[0].file}
                    mimetype={approvalItem.assets[0].props?.mimetype ?? Mimetype.Image}
                    already_uploaded_url={approvalItem.assets[0].already_uploaded_url}
                  />
                )}
                {approvalItem?.assets[0].file?.name ||
                  (approvalItem?.assets[0].uploaded_asset?.ast_original_filename && (
                    <Typography variant="caption" color="textSecondary" align="center">
                      {approvalItem?.assets[0].file?.name ||
                        approvalItem?.assets[0].uploaded_asset?.ast_original_filename}
                    </Typography>
                  ))}
              </Stack>
            </>
          )}
        </Grid>
        <Grid item xs={8}>
          <CardContent>
            <TextField
              variant="outlined"
              label={`Legenda`}
              value={approvalItem.item_description ?? ""}
              onChange={(e) => {
                if (approvalItem.item_id) {
                  dispatch(
                    approvalCreateSlice.actions.changeDescription({
                      item_id: approvalItem.item_id,
                      description: e.target.value,
                    }),
                  );
                } else {
                  toast.error("Não foi possível alterar a descrição");
                }
              }}
              fullWidth
              multiline
              rows={4}
            />
            {apv_status.statusType.stp_type !== IStatusTypeStpTypeEnum.NOT_STARTED && (
              <Stack>
                <Box sx={{ mt: 2 }}>
                  {statusChips.map((chip) => (
                    <Tooltip key={chip.label} title={chip.tooltip} arrow>
                      <Chip
                        style={{ cursor: "pointer" }}
                        key={chip.label}
                        label={chip.label}
                        variant="outlined"
                        color={chip.color}
                        icon={chip.icon}
                        sx={{ mr: 1, mb: 1 }}
                      />
                    </Tooltip>
                  ))}
                </Box>
              </Stack>
            )}
          </CardContent>
          <CardActions>
            <Button size="small" onClick={handleSelectToEdit}>
              Editar
            </Button>
            <Button size="small" onClick={() => handleDeleteApprovalItem(approvalItem)}>
              Remover
            </Button>
          </CardActions>
        </Grid>
      </Grid>
      <InstagramCarouselEdit />
    </Card>
  );
};
