import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useWorkspace } from 'src/hooks/use-workspace';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';
import { useBillingStatus } from 'src/hooks/use-billing';
import { EmptyPlaceholder } from 'src/sections/components/EmptyPlaceholder';
import { StripePricingTable } from 'src/components/stripe/strip-pricing-table';

interface WorkspaceGuardProps {
  children: ReactNode;
}

export const WorkspaceGuard: FC<WorkspaceGuardProps> = (props) => {
  const { data } = useBillingStatus();
  const { children } = props;
  const router = useRouter();

  // if (data.billing === undefined) {
  //   return (
  //     <EmptyPlaceholder
  //       overlineText="You need to choose a plan"
  //       customSubtitle={'In order to create a workspace, you need to choose a plan.'}
  //       showButton={false}
  //     >
  //       <StripePricingTable />
  //     </EmptyPlaceholder>
  //   );
  // }

  return <>{children}</>;
};
