import { useState, type FC } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { Divider, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "src/config/query-key";
import { thumbzApi, useThumbzApi } from "src/services/thumbz-api";
import { useMounted } from "src/hooks/use-mounted";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import { useRouter } from "src/hooks/use-router";
import { LoadingButton } from "@mui/lab";
import { IAsset, IWorkspace, IWorkspaceUpsertDto } from "src/services/thumbz-base-api";
import { ConfirmModal } from "src/components/modal/confirm-modal";
import { useOrganization } from "src/contexts/organization/OrganizationContext";
import FormSection from "src/components/form/FormSection";
import PhotoForm from "src/components/upload/photo-form";
import { PersonalizationForm } from "../personalization-form";
import { Trash01 } from "@untitled-ui/icons-react";

type IUpsertForm = IWorkspaceUpsertDto;

interface IWorkspaceDetailsForm {
  workspace: IWorkspace;
}

export const WorkspaceDetailsForm: FC<IWorkspaceDetailsForm> = ({ workspace }) => {
  const { api } = useThumbzApi();
  const { selectedOrganization } = useOrganization();
  const [photo, setPhoto] = useState<string | null>(getPhotoUrl(workspace.wrk_photo));
  const [open, setOpen] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const router = useRouter();
  const isMounted = useMounted();

  function resetQuery() {
    queryClient.refetchQueries({
      queryKey: queryKeys.workspace.list({
        org_id: String(selectedOrganization),
        with_relations: true,
      }).queryKey,
    });

    queryClient.refetchQueries({
      queryKey: queryKeys.workspace.get({
        wrk_id: String(workspace.wrk_id),
      }).queryKey,
    });
  }

  const formik = useFormik<Partial<IUpsertForm>>({
    initialValues: {
      wrk_id: workspace.wrk_id,
      wrk_name: workspace.wrk_name,
      wrk_description: workspace.wrk_description,
      wrk_slug: workspace.wrk_slug,
    },
    validationSchema: Yup.object<IUpsertForm>({
      wrk_id: Yup.string().uuid("Formato de UUID inválido").optional(),
      wrk_name: Yup.string().required("O nome do workspace é obrigatório"),
      wrk_description: Yup.string().optional().notRequired(),
      wrk_slug: Yup.string()
        .required()
        .matches(/^[a-z0-9-]+$/, "Formato de slug inválido"),
    }),
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        const res = await thumbzApi.workspace.workspaceControllerUpsert({
          ...workspace,
          wrk_id: values.wrk_id,
          wrk_name: String(values.wrk_name),
          wrk_description: values.wrk_description || null,
          wrk_slug: String(values.wrk_slug),
          org_id: String(selectedOrganization?.org_id),
        });

        if (isMounted()) {
          toast.success(`Detalhes do workspace atualizados com sucesso`);
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
          resetQuery();
        }
      } catch (err) {
        if (isMounted()) {
          const errors = getServerErrorArrayMessage(err);
          errors.forEach((error) => toast.error(error));
          helpers.setStatus({ success: false });
          helpers.setSubmitting(false);
        }
      }
    },
  });

  const changeWorkspaceMutationPhoto = useMutation({
    mutationFn: api.workspace.workspaceControllerUpdatePhoto,
    onSuccess(data, variables, context) {
      resetQuery();
      setPhoto(getPhotoUrl(data.wrk_photo));
      toast.success("Ícone atualizado");
    },
    onError(error, variables, context) {
      const errors = getServerErrorArrayMessage(error);
      errors.forEach((error) => toast.error(error));
    },
  });

  const deleteWorkspaceMutation = useMutation({
    mutationFn: thumbzApi.workspace.workspaceControllerDelete,
    onSuccess() {
      toast.success("Workspace removido");
      resetQuery();
      queryClient.refetchQueries();
      queryClient.invalidateQueries();
      router.navigate({
        to: "/",
        from: "/workspaces/$wrk_id",
        params: { wrk_id: workspace.wrk_id },
      });
    },
    onError(error) {
      const errors = getServerErrorArrayMessage(error);
      errors.forEach((error) => toast.error(error));
    },
  });

  return (
    <Stack spacing={2}>
      <form onSubmit={formik.handleSubmit}>
        <FormSection
          title="Detalhes do Workspace"
          description={
            <>
              Atualize os detalhes do seu workspace
              <br />
              <br />
              <strong>Nota:</strong> Essas informações serão usadas para exibir os detalhes do
              workspace no painel de controle.
            </>
          }
          actions={
            <LoadingButton
              sx={{ m: 1 }}
              type="submit"
              loading={formik.isSubmitting}
              variant="contained"
              disabled={!formik.isValid || formik.isSubmitting}
            >
              Salvar
            </LoadingButton>
          }
        >
          <FormikProvider value={formik}>
            <Typography color="text.secondary" component="p" sx={{ mb: 1 }} variant="overline">
              Detalhes do Workspace
            </Typography>
            <TextField
              fullWidth
              label="Nome do Workspace"
              name="wrk_name"
              required
              value={formik.values.wrk_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.wrk_name && Boolean(formik.errors.wrk_name)}
              helperText={formik.touched.wrk_name && formik.errors.wrk_name}
            />

            <TextField
              fullWidth
              label="Descrição do Workspace"
              name="wrk_description"
              value={formik.values.wrk_description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.wrk_description && Boolean(formik.errors.wrk_description)}
              helperText={formik.touched.wrk_description && formik.errors.wrk_description}
            />

            <TextField
              fullWidth
              label="Slug"
              name="wrk_slug"
              required
              disabled
              value={formik.values.wrk_slug}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.wrk_slug && Boolean(formik.errors.wrk_slug)}
              helperText={
                "Alterar o slug pode quebrar links existentes, entre em contato com o suporte para mudanças."
              }
            />
          </FormikProvider>
        </FormSection>
      </form>

      <FormSection
        title="Ícone do workspace"
        description={
          <>
            Atualize o ícone do seu workspace
            <br />
            <br />
            <strong>Nota:</strong> Esse ícone será usado para representar o workspace no painel de
            controle, além de ser exibida na página de aprovação.
          </>
        }
        actions={undefined}
      >
        <PhotoForm
          key={`${photo}`}
          default_photo_url={photo ?? ""}
          loading={changeWorkspaceMutationPhoto.isPending}
          onFormSuccessSubmit={function ({ base64 }: { base64: string | undefined }): void {
            if (!base64) {
              toast.error("Por favor, selecione um ícone");
              return;
            }

            changeWorkspaceMutationPhoto.mutate({
              base64: base64,
              wrk_id: workspace.wrk_id,
            });
          }}
        />
      </FormSection>

      {workspace.personalization && (
        <PersonalizationForm
          personalization={workspace.personalization}
          onSuccessCallback={resetQuery}
        />
      )}

      <Divider />

      <FormSection
        title="Remover workspace"
        description="Envie esse workspace para a lixeira durante 30 dias antes de ser removido permanentemente. Durante esse período, você pode restaurar o workspace."
        actions={undefined}
      >
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <LoadingButton
            fullWidth={false}
            startIcon={<Trash01 />}
            color="error"
            variant="contained"
            onClick={() => setOpen(true)}
          >
            Remover workspace
          </LoadingButton>
        </Stack>
      </FormSection>

      <ConfirmModal
        open={open}
        setOpen={setOpen}
        description="Você tem certeza que deseja excluir este workspace? Esta ação não pode ser desfeita."
        confirmText="Remover"
        cancelText="Cancelar"
        onConfirm={async () =>
          deleteWorkspaceMutation.mutate({
            wrk_id: workspace.wrk_id,
          })
        }
        isPending={deleteWorkspaceMutation.isPending}
        onSuccessful={() => {
          setOpen(false);
        }}
      />
    </Stack>
  );
};

function getPhotoUrl(asset: IAsset | null) {
  if (!asset) {
    return null;
  }

  return `${asset.ast_url}?updated_at=${asset.ast_updated_at}`;
}
