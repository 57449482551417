import { PictureAsPdf } from "@mui/icons-material";
import { CardMedia, Avatar, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import { ISliceItemAssetUpsertDto } from "src/features/approvalsCreate/approvalCreateSlice";
import { getMimetype, Mimetype } from "src/utils/get-mimetype";

interface IInstagramRenderImgPreviewProps {
  showThumbnail?: boolean;
  file: File | null;
  mimetype: Mimetype | undefined | string;
  already_uploaded_url: string | null;
  size?: number;
}

export const InstagramRenderImgPreview: React.FC<IInstagramRenderImgPreviewProps> = ({
  already_uploaded_url,
  file,
  mimetype,
  showThumbnail = true,
  size = 250,
}) => {
  const theme = useTheme();
  const [thumbnail, setThumbnail] = useState<string | null>(null);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const _mimetype = getMimetype(mimetype);

  const captureThumbnail = (file: File) => {
    const videoBlobUrl = URL.createObjectURL(file);
    const video = document.createElement("video");
    video.src = videoBlobUrl;
    video.currentTime = 1; // Captura o frame no segundo 1 do vídeo

    video.onloadeddata = () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      context?.drawImage(video, 0, 0, canvas.width, canvas.height);
      const thumbnailUrl = canvas.toDataURL("image/png");
      setThumbnail(thumbnailUrl);
      URL.revokeObjectURL(videoBlobUrl); // Libera memória
    };
  };

  useEffect(() => {
    if (file && file instanceof Blob && _mimetype === Mimetype.Video) {
      captureThumbnail(file);
      const newVideoUrl = URL.createObjectURL(file);
      setVideoUrl(newVideoUrl);
    } else if (already_uploaded_url && _mimetype === Mimetype.Video) {
      setThumbnail(already_uploaded_url);
      setVideoUrl(already_uploaded_url);
    }
    return () => {
      if (videoUrl) {
        URL.revokeObjectURL(videoUrl); // Libera a URL quando o componente é desmontado
      }
    };
  }, [file, _mimetype, already_uploaded_url, videoUrl]);

  const renderMedia = useCallback(() => {
    let imageUrl = already_uploaded_url || "";

    if (file instanceof Blob && file) {
      imageUrl = URL.createObjectURL(file);
    }

    const height = size;

    switch (_mimetype) {
      case Mimetype.Image:
        return (
          <CardMedia
            component="img"
            image={imageUrl}
            sx={{
              bgcolor: theme.palette.grey["300"],
            }}
            style={{ height, objectFit: "contain", width: "100%" }}
          />
        );
      case Mimetype.Video:
        return (
          <>
            {!showThumbnail ? (
              <img src={thumbnail || imageUrl} style={{ width: "100%", cursor: "pointer" }} />
            ) : (
              <video controls src={imageUrl} style={{ width: "100%" }} />
            )}
          </>
        );
      case Mimetype.Pdf:
        return (
          <>
            {!showThumbnail ? (
              <Avatar variant="square" style={{ width: "100%", height }}>
                <Stack direction="column" spacing={1} alignItems="center" justifyContent="center">
                  <PictureAsPdf color="info" />
                  <Typography variant="caption" color="black">
                    {file?.name ?? "PDF"}
                  </Typography>
                </Stack>
              </Avatar>
            ) : (
              <iframe src={imageUrl} height={height} width="100%" />
            )}
          </>
        );
      default:
        return <p>Unsupported media type</p>;
    }
  }, [already_uploaded_url, file, size, _mimetype, theme.palette.grey, showThumbnail, thumbnail]);

  return renderMedia();
};
