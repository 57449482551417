import { AvatarGroup, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import CustomerAvatarWithIcons from "src/components/avatar/CustomerAvatarWithIcons";
import { InstagramRenderImgPreview } from "src/components/instagram-render-img-preview";
import { ItemStatus } from "src/components/status/item-status";
import { ColumnDef, DataTable } from "src/components/table/data-table";
import { IGroupedTableRow } from "src/features/approval-edit/approval-edit-selectors";
import {
  approvalEditSlice,
  approvalEditThunks,
  ApprovalEditView,
} from "src/features/approval-edit/approvalEditSlice";
import { useIcon } from "src/hooks/use-icon";
import { Mimetype } from "src/utils/get-mimetype";
import { ApprovalEditDrawer } from "./approval-edit-drawer";
import { Refresh } from "@mui/icons-material";

const MEDIA_DEFAULT_SIZE = 40;

const columns: ColumnDef<IGroupedTableRow>[] = [
  {
    name: "Material principal",
    formatter: ({ asset }): React.ReactNode => (
      <InstagramRenderImgPreview
        file={null}
        mimetype={asset?.ast_mimetype as Mimetype}
        already_uploaded_url={asset?.ast_url}
        size={MEDIA_DEFAULT_SIZE}
      />
    ),
  },
  {
    name: "Descrição",
    width: 150,
    formatter: ({ item_description }): React.ReactNode => (
      <Tooltip title={item_description}>
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: 150, // certifique-se de definir a largura
          }}
        >
          {item_description}
        </Typography>
      </Tooltip>
    ),
  },
  {
    name: "Status",
    formatter: ({ status }): React.ReactNode => <ItemStatus status={status} />,
  },
  {
    name: "Comentários",
    formatter: ({ commentsQuantity }): React.ReactNode => <span>{commentsQuantity}</span>,
  },
  {
    name: "Aprovações",
    formatter: ({ customersApprovals }): React.ReactNode => (
      <Stack direction="row" spacing={1}>
        <AvatarGroup max={4}>
          {customersApprovals.map(({ air_status, customer }, index) => {
            const icon = useIcon({ air_status });
            return (
              <CustomerAvatarWithIcons
                customer={customer}
                showIcon
                icon={icon}
                key={`avatar-qty-${customer.cust_id}-${index}`}
                showLegend={false}
                size={MEDIA_DEFAULT_SIZE}
              />
            );
          })}
        </AvatarGroup>
      </Stack>
    ),
  },
];

interface ApprovalTableSectionProps {
  title: string;
  rows: IGroupedTableRow[];
  emptyMessage: string;
}

const ApprovalTableSection: React.FC<ApprovalTableSectionProps> = ({
  title,
  rows,
  emptyMessage,
}) => {
  const dispatch = useAppDispatch();

  function handleRowClick(event: React.MouseEvent, row: IGroupedTableRow) {
    dispatch(approvalEditSlice.actions.selectApprovalItem({ item_id: row.item_id }));
  }

  return (
    <Stack spacing={1}>
      <Typography variant="h6">
        {title} ({rows.length})
      </Typography>
      <DataTable<IGroupedTableRow>
        columns={columns}
        rows={rows}
        selectable={false}
        selected={new Set<string>()}
        uniqueRowId={(row) => row.item_id}
        dense
        emptyMessage={emptyMessage}
        onClick={handleRowClick}
      />
    </Stack>
  );
};

export const ApprovalItemsTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const approval = useAppSelector(approvalEditSlice.selectors.approval);
  const groupedItems = useAppSelector(approvalEditSlice.selectors.approvalItemsGrouped);
  const currentStage = useAppSelector(approvalEditSlice.selectors.currentStage);
  const view = useAppSelector(approvalEditSlice.selectors.view);

  return (
    <>
      <Stack spacing={5}>
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">{currentStage?.stg_name}</Typography>
          {/* <ApprovalTableFilters /> */}
          {approval?.apv_id && (
            <IconButton
              onClick={() => {
                dispatch(approvalEditThunks.fetchApproval({ apv_id: approval?.apv_id }));
              }}
            >
              <Refresh />
            </IconButton>
          )}
        </Stack>

        {view === ApprovalEditView.GROUP ? (
          <Stack spacing={4}>
            <ApprovalTableSection
              title="Pendentes"
              rows={groupedItems.pending}
              emptyMessage="Por enquanto, não há itens pendentes."
            />
            <ApprovalTableSection
              title="Rejeitados"
              rows={groupedItems.rejected}
              emptyMessage="Por enquanto, não há itens rejeitados."
            />
            <ApprovalTableSection
              title="Aprovados"
              rows={groupedItems.approved}
              emptyMessage="Por enquanto, não há itens aprovados."
            />
          </Stack>
        ) : (
          <Stack spacing={4}>
            <ApprovalTableSection
              title="Todos os Itens"
              rows={[...groupedItems.pending, ...groupedItems.approved, ...groupedItems.rejected]}
              emptyMessage="Por enquanto, não há itens."
            />
          </Stack>
        )}
      </Stack>
      <ApprovalEditDrawer />
    </>
  );
};

export default ApprovalItemsTable;
