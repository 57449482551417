import type { FC } from "react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import ArrowRightIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { IWorkspaceUpsertForm } from "src/contexts/workspace/workspace-form";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useThumbzApi } from "src/services/thumbz-api";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import toast from "react-hot-toast";
import { useAppSelector } from "src/app/hooks";
import { organizationSlice } from "src/features/organization/organizationSlice";
import slugify from "slugify";
import { LoadingButton } from "@mui/lab";
import { useWorkspaceForm } from "src/hooks/use-workspace-form";

interface JobDetailsStepProps {
  onNext?: () => void;
  onBack?: () => void;
}

interface Values {
  details: IWorkspaceUpsertForm["details"];
}

const getInitialValues = ({ details }: Partial<IWorkspaceUpsertForm>): Values => {
  return {
    details: {
      wrk_id: details?.wrk_id || undefined,
      wrk_name: details?.wrk_name || "",
      wrk_slug: details?.wrk_slug || "",
      wrk_description: details?.wrk_description || "",
    },
  };
};

const validationSchema = Yup.object({
  details: Yup.object({
    wrk_name: Yup.string().min(5).max(255).required("Nome do workspace é obrigatório"),
    // wrk_description é opcional, mas se for fornecido, deve ter entre 5 e 255 caracteres
    wrk_description: Yup.string().min(5).max(255).optional(),
    wrk_slug: Yup.string()
      .required("Slug do workspace é obrigatório")
      .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, "Somente letras minúsculas e números são permitidos"),
    wrk_id: Yup.string().optional(),
  }),
});

export const WorkspaceUpsertStep1Details: FC<JobDetailsStepProps> = (props) => {
  const queryClient = useQueryClient();
  const selectedOrganization = useAppSelector(organizationSlice.selectors.selectedOrganization);
  const { api } = useThumbzApi();
  const { state, dispatch } = useWorkspaceForm();
  const { onNext, ...other } = props;

  const upsertWorkspace = useMutation({
    mutationFn: api.workspace.workspaceControllerUpsert,
    onSuccess: (data) => {
      dispatch({
        type: "SET_WORKSPACE_DETAILS",
        payload: {
          wrk_description: data.wrk_description || "",
          wrk_name: data.wrk_name,
          wrk_slug: slugifyStr(data.wrk_slug),
          wrk_id: data.wrk_id,
        },
      });
      dispatch({
        type: "SET_WORKSPACE_PHOTO",
        payload: {
          base64: data.wrk_photo?.ast_url || "",
        },
      });
      dispatch({
        type: "SET_WORKSPACE_PERSONALIZATION",
        payload: {
          per_id: data.personalization?.per_id || undefined,
          per_primary_hex_color: data.personalization?.per_primary_hex_color || "",
          per_text_hex_color: data.personalization?.per_text_hex_color || "",
          per_url: data.personalization?.per_url || "",
        },
      });
      queryClient.refetchQueries({
        predicate: (query) => query.queryKey.includes("workspace"),
      });
      if (onNext) {
        onNext();
      }
    },
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message)),
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await formik.validateForm();
      formik.handleSubmit();
    } catch (error) {
      console.error("Erro na submissão", error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(state.formState) || { details: [] },
    validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      if (!selectedOrganization?.org_id) {
        toast.error("Selecione uma organização");
        return;
      }

      await upsertWorkspace.mutateAsync({
        org_id: selectedOrganization.org_id,
        wrk_id: state.formState?.details?.wrk_id,
        wrk_description: values.details.wrk_description || null,
        wrk_slug: slugifyStr(values.details.wrk_slug),
        wrk_name: values.details.wrk_name,
      });
    },
  });

  const handleWorkspaceNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    const newSlug = slugifyStr(newName);
    formik.setFieldValue("details.wrk_name", newName);
    formik.setFieldValue("details.wrk_slug", newSlug); // Atualiza o slug no formik
    dispatch({
      type: "SET_WORKSPACE_DETAILS",
      payload: {
        wrk_description: formik.values.details.wrk_description || "",
        wrk_name: newName,
        wrk_slug: newSlug, // Atualiza o slug no estado
        wrk_id: formik.values.details.wrk_id,
      },
    });
  };

  const handleWorkspaceSlugChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const slugged = slugifyStr(e.target.value);
    formik.setFieldValue("details.wrk_slug", slugged);
    dispatch({
      type: "SET_WORKSPACE_DETAILS",
      payload: {
        wrk_description: formik.values.details.wrk_description || "",
        wrk_name: formik.values.details.wrk_name,
        wrk_slug: slugged,
        wrk_id: formik.values.details.wrk_id,
      },
    });
  };

  const handleWorkspaceDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("details.wrk_description", e.target.value);
    dispatch({
      type: "SET_WORKSPACE_DETAILS",
      payload: {
        wrk_description: e.target.value,
        wrk_name: formik.values.details.wrk_name,
        wrk_id: formik.values.details.wrk_id,
        wrk_slug: state.formState.details.wrk_slug,
      },
    });
  };

  function getSlugHelpText() {
    if (formik.touched.details?.wrk_slug && formik?.errors?.details?.wrk_slug) {
      return formik.errors.details.wrk_slug;
    }

    return "O slug é uma parte da URL que identifica seu workspace. Ele deve conter apenas letras minúsculas e números. Ex: empresa-x. Isso será usado para acessar seu workspace através da URL.";
  }

  function getNameHelpText() {
    if (formik.touched.details?.wrk_name && formik?.errors?.details?.wrk_name) {
      return formik.errors.details.wrk_name;
    }

    return "O nome do workspace é o nome de identificação do seu cliente, departamento ou projeto. Ex: Empresa X. Isso será exibido em vários lugares, principalmente para aprovadores.";
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={3} {...other}>
        <div>
          <Typography variant="h6">Conte-nos sobre seu workspace</Typography>
          <Typography variant="caption">
            O workspace funciona como um agrupador para sua organização, reunindo aprovadores,
            trabalhos e outras informações relevantes.
          </Typography>
        </div>
        <Stack spacing={3}>
          <TextField
            error={!!(formik.touched.details?.wrk_name && formik?.errors?.details?.wrk_name)}
            fullWidth
            helperText={getNameHelpText()}
            label="Nome do Workspace *"
            name="wrk_name"
            placeholder="Ex: Empresa X"
            disabled={formik.isSubmitting}
            onBlur={formik.handleBlur}
            onChange={handleWorkspaceNameChange}
            value={formik.values.details.wrk_name}
            sx={{ flexGrow: 1 }}
          />
          <TextField
            error={!!(formik.touched.details?.wrk_slug && formik?.errors?.details?.wrk_slug)}
            fullWidth
            helperText={getSlugHelpText()}
            label="Slug do Workspace *"
            name="wrk_slug"
            placeholder="Ex: empresa-x"
            onBlur={formik.handleBlur}
            onChange={handleWorkspaceSlugChange}
            value={formik.values.details.wrk_slug}
            sx={{ flexGrow: 1 }}
          />
          <TextField
            error={
              !!(
                formik.touched.details?.wrk_description && formik?.errors?.details?.wrk_description
              )
            }
            fullWidth
            helperText={
              formik.touched.details?.wrk_description && formik?.errors?.details?.wrk_description
            }
            label="Descrição do Workspace"
            name="wrk_description"
            placeholder="Insira uma breve descrição do seu workspace"
            disabled={formik.isSubmitting}
            onBlur={formik.handleBlur}
            onChange={handleWorkspaceDescriptionChange}
            value={formik.values.details.wrk_description}
            sx={{ flexGrow: 1 }}
          />
        </Stack>

        <Stack alignItems="center" direction="row" spacing={2}>
          <LoadingButton
            type="submit"
            loading={formik.isSubmitting || upsertWorkspace.isPending}
            endIcon={
              <SvgIcon>
                <ArrowRightIcon />
              </SvgIcon>
            }
            variant="contained"
          >
            Continuar
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
};

WorkspaceUpsertStep1Details.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
};

function slugifyStr(str: string) {
  return slugify(str, { lower: true });
}
