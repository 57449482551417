export const paths = {
  external: {
    approval_stage_customer: ({ asc_id, wrk_slug }: { asc_id: string; wrk_slug: string }) =>
      `${process.env.NEXT_PUBLIC_APPROVAL_URL}/aprovar/${wrk_slug}/${asc_id}`,
  },
  index: `/auth/firebase/login`,
  auth: {
    firebase: {
      login: `/auth/firebase/login`,
      register: `/auth/firebase/register`,
      forgotPassword: `/auth/firebase/forgot-password`,
      layout: `/auth/firebase/layout`,
    },
  },
  dashboard: {
    index: `/workspaces`,
    organization: {
      index: `/organizacao`,
      collaborators: `/organizacao/colaboradores`,
      detalhes: `/organizacao/detalhes`,
      billing: `/organizacao/faturamento`,
    },
    account: {
      general: `/conta/geral`,
      security: `/conta/seguranca`,
      notifications: `/conta/notificacoes`,
    },
    workspaces: {
      index: `/workspaces`,
      create: `/workspaces/criar`,
      details: ({ wrk_id }: { wrk_id: string }) => `/workspaces/${wrk_id}`,
      approval: ({ wrk_id }: { wrk_id: string }) => ({
        upsert: ({ apv_id }: { apv_id: string | null }) =>
          `/workspaces/${wrk_id}/aprovacao/${apv_id}`,
        details: ({ apv_id }: { apv_id: string }) =>
          `/workspaces/${wrk_id}/aprovacao/${apv_id}/detalhes`,
      }),
      flow: ({ wrk_id }: { wrk_id: string }) => ({
        index: `/workspaces/${wrk_id}/flow`,
        details: ({ flow_id }: { flow_id: string }) => `/workspaces/${wrk_id}/flow/${flow_id}`,
      }),
    },
    convite: {
      join: ({ token }: { token: string }) => `/convite/${token}`,
    },
  },
  marketing: {
    index: `/marketing`,
    layout: `/marketing/layout`,
    pricing: `/marketing/pricing`,
  },
  contact: `/contact`,
  verificar: `/verificar`,
  docs: `https://docs.thumbz.app`,
  notAuthorized: `/errors/401`,
  notFound: `/errors/404`,
  serverError: `/errors/500`,
  terms: `https://www.thumbz.app/terms`,
  privacy: `https://www.thumbz.app/privacy`,
};
