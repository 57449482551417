import {
  IAsset,
  ICustomer,
  IApprovalItemRevisionAirStatusEnum,
  IApprovalItem,
  IApprovalStageCustomer,
  IApprovalItemCommentAicByEnum,
  IStatusTypeStpTypeEnum,
  IApprovalItemRevision,
} from "../../services/thumbz-base-api";
import { ApprovalEditState } from "./approvalEditSlice";

export interface IGroupedTableRow {
  item_id: string;
  item_description: string;
  item_order: number;
  asset: IAsset;
  customersApprovals: {
    customer: ICustomer;
    air_status: IApprovalItemRevisionAirStatusEnum;
  }[];
  commentsQuantity: number;
  status: IApprovalItemRevisionAirStatusEnum;
}

export interface IGroupedTable {
  pending: IGroupedTableRow[];
  approved: IGroupedTableRow[];
  rejected: IGroupedTableRow[];
}

export const approvalEditSelectors = {
  view: (state: ApprovalEditState) => state.view,
  sort: (state: ApprovalEditState) => state.sort,
  error: (state: ApprovalEditState) => state.error,
  status: (state: ApprovalEditState) => state.status,
  getApproval: (state: ApprovalEditState) => state.approval,
  getWorkspace: (state: ApprovalEditState) => state.approval?.workspace,

  // Funções
  currentStage: (state: ApprovalEditState) => {
    const first = state.approval?.stages.find((stage) => stage.stg_completed === false) ?? null;

    if (first) return first;

    return state.approval?.stages[0];
  },

  allStages: (state: ApprovalEditState) => state.approval?.stages ?? [],

  currentStageIndex: (state: ApprovalEditState) => {
    const currentStage = approvalEditSelectors.currentStage(state);

    return state.approval?.stages.findIndex((stage) => stage.stg_id === currentStage?.stg_id) ?? 0;
  },

  currentStageCustomers: (state: ApprovalEditState) => {
    const currentStage = approvalEditSelectors.currentStage(state);

    return currentStage?.approvalStageCustomers;
  },

  shouldShowStageStepper: (state: ApprovalEditState) => {
    const stages = approvalEditSelectors.allStages(state);

    return stages.length > 1;
  },

  getApprovalItemStatus: (
    approvalItem: IApprovalItem,
    approvalStageCustomers: IApprovalStageCustomer[],
  ): IApprovalItemRevisionAirStatusEnum => {
    const revisions =
      approvalStageCustomers.flatMap(
        (asc) =>
          asc.revisions.find(
            (revision) => revision.approvalitem.item_id === approvalItem.item_id,
          ) ?? [],
      ) ?? [];

    if (
      revisions.every(
        (revision) => revision.air_status === IApprovalItemRevisionAirStatusEnum.Approved,
      )
    ) {
      return IApprovalItemRevisionAirStatusEnum.Approved;
    }

    if (
      revisions.some(
        (revision) => revision.air_status === IApprovalItemRevisionAirStatusEnum.Requested,
      )
    ) {
      return IApprovalItemRevisionAirStatusEnum.Requested;
    }

    return IApprovalItemRevisionAirStatusEnum.Pending;
  },

  // Agrupamento por status: Pending, Approved, Rejected (Requested)
  approvalItemsGrouped: (state: ApprovalEditState) => {
    const currentStage = approvalEditSelectors.currentStage(state);
    const approvalItems = state.approval?.approvalItems ?? [];

    // Mapeia os approvalItems e computa o status baseado nas revisões
    const computedItems: IGroupedTableRow[] = approvalItems.map((item) => ({
      item_id: item.item_id,
      item_description: item.item_description ?? "",
      item_order: item.item_order,
      asset: item.approvalItemAsset[0].asset,
      customersApprovals:
        currentStage?.approvalStageCustomers.map((approvalStageCustomers) => ({
          customer: approvalStageCustomers.customer,
          air_status:
            approvalStageCustomers.revisions.find(
              (revision) => revision.approvalitem.item_id === item.item_id,
            )?.air_status ?? IApprovalItemRevisionAirStatusEnum.Pending,
        })) ?? [],
      commentsQuantity:
        currentStage?.approvalStageCustomers.reduce(
          (acc, approvalStageCustomers) =>
            acc +
            approvalStageCustomers.revisions
              .filter((revision) => revision.approvalitem.item_id === item.item_id)
              .reduce(
                (acc, revision) =>
                  acc +
                  (revision.air_comments ?? []).filter(
                    (c) => c.aic_by !== IApprovalItemCommentAicByEnum.System,
                  ).length,
                0,
              ),
          0,
        ) ?? 0,
      status: approvalEditSelectors.getApprovalItemStatus(
        item,
        currentStage?.approvalStageCustomers ?? [],
      ),
    }));

    const _approved: IGroupedTableRow[] = computedItems.filter(({ customersApprovals }) =>
      customersApprovals.every(
        ({ air_status }) => air_status === IApprovalItemRevisionAirStatusEnum.Approved,
      ),
    );

    const _rejected: IGroupedTableRow[] = computedItems.filter(({ customersApprovals }) =>
      customersApprovals.some(
        ({ air_status }) => air_status === IApprovalItemRevisionAirStatusEnum.Requested,
      ),
    );

    const _pending: IGroupedTableRow[] = computedItems.filter(
      ({ customersApprovals }) =>
        // Caso não seja nem aprovado nem rejeitado, então é pendente
        !customersApprovals.every(
          ({ air_status }) => air_status === IApprovalItemRevisionAirStatusEnum.Approved,
        ) &&
        customersApprovals.every(
          ({ air_status }) => air_status !== IApprovalItemRevisionAirStatusEnum.Requested,
        ),
    );

    const groupedItems: IGroupedTable = {
      approved: _approved,
      rejected: _rejected,
      pending: _pending,
    };

    // Ordenação baseada na data de vencimento (asc/desc)
    Object.entries(groupedItems).forEach(([key, items]) => {
      items.sort((a: IGroupedTableRow, b: IGroupedTableRow) => {
        if (state.sort === "asc") {
          return a.item_order - b.item_order;
        } else {
          return b.item_order - a.item_order;
        }
      });
    });

    return groupedItems;
  },

  isStageCompleted: (state: ApprovalEditState, stg_id: string) => {
    const currentStage = approvalEditSelectors
      .allStages(state)
      .find((stage) => stage.stg_id === stg_id);

    return currentStage?.stg_completed ?? false;
  },

  stageCompletedPercentage: (state: ApprovalEditState, stg_id: string) => {
    const currentStage = approvalEditSelectors
      .allStages(state)
      .find((stage) => stage.stg_id === stg_id);

    const totalRevisions = currentStage?.approvalStageCustomers.reduce(
      (acc, { revisions }) => acc + revisions.length,
      0,
    );
    const approvedRevisions = currentStage?.approvalStageCustomers.reduce(
      (acc, { revisions }) =>
        acc +
        revisions.filter(
          (revision) => revision.air_status === IApprovalItemRevisionAirStatusEnum.Approved,
        ).length,
      0,
    );

    return totalRevisions ? ((approvedRevisions ?? 0) / totalRevisions) * 100 : 0;
  },

  selectedApprovalItem: (state: ApprovalEditState) =>
    state.approval?.approvalItems.find((item) => item.item_id === state.selected_item_id),

  getApprovalStageCustomer: (state: ApprovalEditState) => {
    const currentStage = approvalEditSelectors.currentStage(state);

    return currentStage?.approvalStageCustomers.find(
      ({ asc_id }) => asc_id === state.selected_chat_asc_id,
    );
  },

  getChatCustomers: (state: ApprovalEditState) => {
    const currentStage = approvalEditSelectors.currentStage(state);

    return (currentStage?.approvalStageCustomers ?? []).map((asc) => ({
      approvalStageCustomer: asc,
      commentsQuantity: asc.revisions.reduce(
        (acc, revision) =>
          acc +
          (revision.air_comments ?? []).filter(
            ({ customer, aic_by }) => aic_by !== IApprovalItemCommentAicByEnum.System,
          ).length,
        0,
      ),
    }));
  },

  getCommentsFromSelectedApprovalItem: (state: ApprovalEditState) => {
    const currentStage = approvalEditSelectors.currentStage(state);
    const selectedApprovalItem = approvalEditSelectors.selectedApprovalItem(state);

    if (!currentStage || !selectedApprovalItem) return [];

    return currentStage.approvalStageCustomers.flatMap((asc) =>
      asc.revisions
        .filter((revision) => revision.approvalitem.item_id === selectedApprovalItem.item_id)
        .flatMap((revision) => revision.air_comments ?? [])
        .sort((a, b) => {
          if (a.aic_created_at > b.aic_created_at) return -1;
          if (a.aic_created_at < b.aic_created_at) return 1;
          return 0;
        }),
    );
  },

  getCommentsFromSelectedCustomer: (state: ApprovalEditState) => {
    const approvalStageCustomer = approvalEditSelectors.getApprovalStageCustomer(state);
    if (!approvalStageCustomer) return [];

    return approvalStageCustomer.revisions
      .filter((revision) => revision.approvalitem.item_id === state.selected_item_id)
      .flatMap((revision) => revision.air_comments ?? []);
  },

  countStageStatus: (state: ApprovalEditState) => {
    const currentStage = approvalEditSelectors.currentStage(state);

    return {
      [IStatusTypeStpTypeEnum.NOT_STARTED]: currentStage?.approvalStageCustomers.filter((asc) =>
        asc.revisions.every(
          (revision) => revision.air_status === IApprovalItemRevisionAirStatusEnum.Pending,
        ),
      ).length,
      [IStatusTypeStpTypeEnum.IN_REVIEW]: currentStage?.approvalStageCustomers.filter((asc) =>
        asc.revisions.some(
          (revision) => revision.air_status === IApprovalItemRevisionAirStatusEnum.Requested,
        ),
      ).length,
      [IStatusTypeStpTypeEnum.REQUEST_CHANGED]: currentStage?.approvalStageCustomers.filter((asc) =>
        asc.revisions.some(
          (revision) => revision.air_status === IApprovalItemRevisionAirStatusEnum.Requested,
        ),
      ).length,
      [IStatusTypeStpTypeEnum.DONE]: currentStage?.approvalStageCustomers.filter((asc) =>
        asc.revisions.every(
          (revision) => revision.air_status === IApprovalItemRevisionAirStatusEnum.Approved,
        ),
      ).length,
    };
  },

  getRevision: (state: ApprovalEditState): IApprovalItemRevision | undefined => {
    const approvalStageCustomer = approvalEditSelectors.getApprovalStageCustomer(state);
    if (!approvalStageCustomer) return undefined;

    const res = approvalStageCustomer.revisions.find(
      (revision) => revision.approvalitem.item_id === state.selected_item_id,
    );

    return res;
  },

  countCommentsFromSelectedCustomer: (state: ApprovalEditState) => {
    const comments = approvalEditSelectors.getCommentsFromSelectedCustomer(state);

    return comments.filter((comment) => comment.aic_by !== IApprovalItemCommentAicByEnum.System)
      .length;
  },

  // Outros seletores...
  currentPage: (state: ApprovalEditState) => state.currentPage,
  rowsPerPage: (state: ApprovalEditState) => state.rowsPerPage,
};
