import React, { useEffect, useState } from "react";
import { Grid, Stack, Skeleton } from "@mui/material";
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";
import { EmptyPlaceholder } from "../components/EmptyPlaceholder";
import {
  approvalCreateSlice,
  approvalThunks,
} from "src/features/approvalsCreate/approvalCreateSlice";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { useThumbzApi } from "src/services/thumbz-api";
import { IApproval } from "src/services/thumbz-base-api";
import { InstagramCarouselApprovalItemCard } from "src/components/instagram-carousel-post";
import toast from "react-hot-toast";
import { ApprovalAddFile } from "./approval-add-file";
import { CloudUpload, Lightbulb } from "@mui/icons-material";
import { GenericHint } from "src/components/hint/generic-hint";

interface IApprovalUpload {
  approval: IApproval;
}

export const ApprovalUpload: React.FC<IApprovalUpload> = ({ approval }) => {
  const isLoading = useAppSelector((state) => state.approvalCreate.status === "loading");
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isFileDraggingAndDrop, setIsFileDraggingAndDrop] = useState<boolean>(false);
  const { api } = useThumbzApi();
  const dispatch = useAppDispatch();
  const { approvalItems, error, status } = useAppSelector((state) => state.approvalCreate);

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    // Evita re-renderização contínua
    if (!isFileDraggingAndDrop) {
      setIsFileDraggingAndDrop(true);
    }
  };

  const handleDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    // Evita que o estado seja alterado constantemente
    if (isFileDraggingAndDrop) {
      setIsFileDraggingAndDrop(false);
    }
  };

  const fileArrayToFileList = (files: File[]): FileList => {
    const dataTransfer = new DataTransfer();
    files.forEach((file) => dataTransfer.items.add(file));
    return dataTransfer.files;
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const filesArray = Array.from(event.dataTransfer.files);
    const fileList = fileArrayToFileList(filesArray);
    dispatch(
      approvalCreateSlice.actions.handleAddFile({ files: fileList, apv_id: approval.apv_id }),
    );
    setIsFileDraggingAndDrop(false);
    toast.success("Materiais adicionados com sucesso");
  };

  useEffect(() => {
    dispatch(approvalThunks.fetchApprovalItems({ apv_id: approval.apv_id }));
  }, [approval.apv_id, dispatch]);

  const onDragEnd = (result: DropResult) => {
    setIsDragging(false);
    const { destination, source, combine } = result;

    if (!destination && !combine) return;

    if (combine) {
      const payload = {
        sourceIndex: source.index,
        combineIndex: parseInt(combine.draggableId, 10),
      };
      dispatch(
        approvalCreateSlice.actions.mergeApprovalItems({
          combineIndex: payload.combineIndex,
          sourceIndex: payload.sourceIndex,
          destinationIndex: payload.combineIndex,
        }),
      );
    } else if (destination) {
      const payload = {
        sourceIndex: source.index,
        destinationIndex: destination.index,
      };
      dispatch(
        approvalCreateSlice.actions.reorderApprovalItems({
          destinationIndex: payload.destinationIndex,
          sourceIndex: payload.sourceIndex,
        }),
      );
    }
  };

  const _handleAddFile = async (files: FileList | null) => {
    if (files) {
      dispatch(approvalCreateSlice.actions.handleAddFile({ files, apv_id: approval.apv_id }));
    }
  };

  const handleUnmergeAll = (index: number) => {
    dispatch(approvalCreateSlice.actions.unmergeAllFromSingleApprovalItem(index));
  };

  const handleUnmergeSingle = (approvalItemIndex: number, assetsIndex: number) => {
    dispatch(
      approvalCreateSlice.actions.unmergeSingleAssetsFromApprovalItem({
        approvalItemIndex,
        assetsIndex,
      }),
    );
  };

  function handleUploadAll() {
    for (const approvalItem of approvalItems) {
      dispatch(approvalThunks.upsertApprovalItems(approvalItem));
    }
  }

  if (!approvalItems) {
    return <div>Approval items not found</div>;
  }

  const hintText = "Arraste uma imagem sobre a outra para formar um carrossel";

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
        <GenericHint
          text={hintText}
          IconAsset={Lightbulb} // Sem os parênteses
        />
        <ApprovalAddFile onAddFile={_handleAddFile} disabled={isLoading} />
      </Stack>
      <div
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragLeave={handleDragLeave} // Adiciona o evento de saída
        style={{
          position: "relative",
          border: isFileDraggingAndDrop ? "2px dashed #ccc" : "none",
          padding: isFileDraggingAndDrop ? "20px" : "0",
          textAlign: isFileDraggingAndDrop ? "center" : "initial",
        }}
      >
        {isFileDraggingAndDrop && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
              zIndex: 10,
              pointerEvents: "none",
            }}
          >
            <span>Soltar Imagens</span>
          </div>
        )}
        {approvalItems.length === 0 && (
          <EmptyPlaceholder
            overlineText="Arraste e solte materiais aqui"
            customSubtitle='ou clique no botão "Adicionar" para fazer upload'
            showButton={false}
          />
        )}
        <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={() => setIsDragging(true)}>
          <Droppable droppableId="approvalItems" direction="vertical" isCombineEnabled>
            {(provided) => (
              <Grid container spacing={2} ref={provided.innerRef} {...provided.droppableProps}>
                {approvalItems.map((approvalItem, index) => {
                  const approvalStageCustomers = approval.stages.flatMap(
                    (stage) => stage.approvalStageCustomers,
                  );
                  return (
                    <Draggable key={index} draggableId={`${index}`} index={index}>
                      {(provided, snapshot) => (
                        <Grid
                          item
                          xs={12}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            opacity: snapshot.isDragging || snapshot.combineTargetFor ? 0.5 : 1,
                          }}
                        >
                          <span style={{ display: isLoading ? "none" : "inherit" }}>
                            <InstagramCarouselApprovalItemCard
                              approvalItem={approvalItem}
                              onUnmergeAll={() => handleUnmergeAll(index)}
                              onUnmergeSingle={(assetsIndex) =>
                                handleUnmergeSingle(index, assetsIndex)
                              }
                              approvalStageCustomers={approvalStageCustomers}
                              enableThumbnail={!isDragging}
                              apv_status={approval.apv_status}
                            />
                          </span>
                          <Skeleton
                            style={{ display: isLoading ? "inherit" : "none" }}
                            variant="rounded"
                            width="100%"
                            height={200}
                            animation="wave"
                          />
                        </Grid>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Stack>
  );
};
