import type { FC, ReactNode } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import type { Theme } from "@mui/material/styles/createTheme";

import { useSections, type Section } from "../config";
import { MobileNav } from "../mobile-nav";
import { TopNav } from "./top-nav";
import { useMobileNav } from "./use-mobile-nav";
import { Box } from "@mui/material";
import { NavColor } from "../../../types/settings";
// import { AccountBillingStatus } from "../../../sections/dashboard/account/account-billing-status";
import { OrganizationGuard } from "../../../contexts/organization/OrganizationContext";

const HorizontalLayoutRoot = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
});

const HorizontalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

interface HorizontalLayoutProps {
  children?: ReactNode;
  navColor?: NavColor;
  sections?: Section[];
}

export const HorizontalLayout: FC<HorizontalLayoutProps> = (props) => {
  const sections = useSections();
  const { children, navColor } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const mobileNav = useMobileNav();
  // TODO: usePathname
  // const pathname = usePathname();

  return (
    <>
      <TopNav color={navColor} onMobileNav={mobileNav.handleOpen} sections={sections} />
      {!lgUp && (
        <MobileNav
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      <HorizontalLayoutRoot>
        <Box px={1}>
          {/* TODO: */}
          {/* <AccountBillingStatus force_hide={pathname.includes("billing")} /> */}
        </Box>
        <HorizontalLayoutContainer>
          <OrganizationGuard>{children}</OrganizationGuard>
        </HorizontalLayoutContainer>
      </HorizontalLayoutRoot>
    </>
  );
};
