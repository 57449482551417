import { createFileRoute } from "@tanstack/react-router";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { usePageView } from "src/hooks/use-page-view";
import { useThumbzApi } from "src/services/thumbz-api";
import { useSelector } from "react-redux";
import { organizationSlice } from "src/features/organization/organizationSlice";
import { Avatar, Button, Card, CardContent, Skeleton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { IPageContainerProps, PageContainer } from "src/components/page-container";
import { queryKeys } from "src/config/query-key";
import { RenderAvatar } from "src/components/avatar/RenderAvatar";
import { paths } from "src/paths";
import { Add } from "@mui/icons-material";
import { RouterLink } from "src/components/router-link";
import Link from "@mui/material/Link";

export const Route = createFileRoute("/_authenticated/workspaces/")({
  component: () => <Page />,
});

const Page = () => {
  const selectedOrganization = useSelector(organizationSlice.selectors.selectedOrganization);
  usePageView();

  const { api } = useThumbzApi();

  const workspaceQuery = useQuery({
    queryKey: queryKeys.workspace.list({
      org_id: String(selectedOrganization?.org_id),
    }).queryKey,
    queryFn: () =>
      api.workspace.workspaceControllerList({
        org_id: String(selectedOrganization?.org_id),
      }),
    enabled: !!selectedOrganization?.org_id,
  });

  const pageContainerProps: IPageContainerProps =
    workspaceQuery.data?.length === 0
      ? {
          children: undefined,
          title: undefined,
          breadcrumbs: undefined,
          leftNode: undefined,
          subtitle: undefined,
          seo: undefined,
        }
      : {
          children: undefined, // Add the children property here
          title: "Workspaces da organização",
          subtitle: "Aqui você pode visualizar e gerenciar os workspaces da sua organização.",
          breadcrumbs: [
            {
              href: paths.dashboard.index,
              label: "Dashboard",
            },
            {
              href: paths.dashboard.workspaces.index,
              label: "Workspaces",
            },
          ],
        };

  if (workspaceQuery.isLoading)
    <Stack spacing={2}>
      {[1, 2, 3, 4, 5].map((i) => (
        <Card key={`skeleton-${i}`}>
          <CardContent>
            <Stack spacing={2}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Stack>
          </CardContent>
        </Card>
      ))}
    </Stack>;

  return (
    <>
      <PageContainer {...pageContainerProps}>
        {workspaceQuery.data?.length === 0 && (
          <Stack alignItems="center" justifyContent="center" spacing={3}>
            <Stack spacing={3}>
              <Stack
                flex={1}
                flexGrow={1}
                spacing={1}
                alignContent={"center"}
                justifyContent={"center"}
              >
                {/* make avatar align on center */}

                <Stack spacing={4} alignItems="center">
                  <Avatar
                    variant="square"
                    src="/assets/errors/error-500.png"
                    sx={{
                      mx: "auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: 200,
                      height: 200,
                    }}
                  ></Avatar>
                  <Stack alignItems="center" spacing={1}>
                    <Typography color="text.secondary" variant="h3">
                      Que ótimo ter você por aqui!
                    </Typography>
                    <Typography variant="body1">
                      Agora é o momento de criar seu primeiro Workspace e começar a simplificar suas
                      aprovações.
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Stack
                alignItems="center"
                direction="row"
                justifyContent="center"
                spacing={3}
                sx={{ mx: "auto", mt: 3 }}
              >
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  href={paths.dashboard.workspaces.create}
                  startIcon={<Add />}
                >
                  Criar meu primeiro workspace
                </Button>
              </Stack>
            </Stack>
          </Stack>
        )}

        {workspaceQuery.data?.map((workspace) => (
          <Link
            key={workspace.wrk_id}
            color="text.primary"
            component={RouterLink}
            href={paths.dashboard.workspaces.details({ wrk_id: workspace.wrk_id })}
            variant="subtitle2"
          >
            <Card key={workspace.wrk_id} style={{ cursor: "pointer" }}>
              <Stack>
                <Stack spacing={2} direction="row" alignItems="center">
                  <RenderAvatar
                    src={workspace?.wrk_photo?.ast_url ?? undefined}
                    variant="rounded"
                    sx={{ width: 100, height: 100 }}
                    name={workspace?.wrk_name}
                    updated_at={workspace?.wrk_photo?.ast_updated_at}
                  />
                  <CardContent>
                    <Typography variant="body1">{workspace.wrk_name}</Typography>
                    <Typography variant="body2">{workspace.wrk_description}</Typography>
                  </CardContent>
                </Stack>
              </Stack>
            </Card>
          </Link>
        ))}
      </PageContainer>
    </>
  );
};
