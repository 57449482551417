import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { organizationSlice } from "../features/organization/organizationSlice";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "redux";
import { approvalEditSlice } from "../features/approval-edit/approvalEditSlice";
import { approvalCreateSlice } from "../features/approvalsCreate/approvalCreateSlice";
import { authSlice } from "../features/auth/authSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  approvalCreate: approvalCreateSlice.reducer,
  approvalEdit: approvalEditSlice.reducer,
  organization: organizationSlice.reducer,
  auth: authSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
