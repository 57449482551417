import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "@mui/material/styles";
import { IApproval, IStatusType } from "src/services/thumbz-base-api";
import { ApprovalViewProps } from "./approval-view-selector";
import { queryKeys } from "src/config/query-key";
import { useThumbzApi } from "src/services/thumbz-api";
import { Card, CardHeader, Typography, Grid, Box, Stack, AvatarGroup } from "@mui/material";
import { RenderAvatar } from "src/components/avatar/RenderAvatar";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useNavigate } from "@tanstack/react-router";

// Tipagem para os dados de Kanban
interface KanbanCardData extends IApproval {}

export const ApprovalKanban: React.FC<ApprovalViewProps> = ({ wrk_id, onDelete, approvals }) => {
  const navigate = useNavigate();
  const { api } = useThumbzApi();
  const theme = useTheme(); // Detectar o tema atual (light ou dark)
  const [columns, setColumns] = useState<IStatusType[]>([]);

  const statusQuery = useQuery({
    queryKey: queryKeys.status.statusControllerFindAll().queryKey,
    queryFn: () => api.status.statusControllerFindAll(),
  });

  useEffect(() => {
    if (statusQuery.data) {
      setColumns(statusQuery.data);
    }
  }, [statusQuery.data]);

  function handleCardClick(apv_id: string) {
    navigate({
      from: "/workspaces/$wrk_id",
      to: `/workspaces/$wrk_id/aprovacao/$apv_id`,
      params: { wrk_id, apv_id },
    });
  }

  // Transformando os dados de approvals para o formato que o Kanban espera
  const kanbanData: KanbanCardData[] = approvals;

  const KanbanCard: React.FC<KanbanCardData> = ({ apv_id, apv_name, apv_created_at, stages }) => {
    const allCustomers = stages.flatMap(({ approvalStageCustomers }) => approvalStageCustomers);
    const currentStage = stages
      .sort((a, b) => a.stg_order - b.stg_order)
      .find(({ stg_completed }) => !stg_completed);

    return (
      <Card
        variant="elevation"
        elevation={3}
        onClick={() => handleCardClick(apv_id)}
        sx={{
          cursor: "pointer",
          "&:hover": {
            boxShadow: theme.shadows[12],
          },
        }}
      >
        <Stack spacing={1} p={2}>
          <CardHeader
            sx={{ p: 0 }}
            title={<Typography variant="subtitle1">{apv_name}</Typography>}
            subheader={
              <Stack>
                <Typography variant="body2" color="textSecondary">
                  Etapa: {currentStage?.stg_name}
                </Typography>
              </Stack>
            }
          />
          <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
            <AvatarGroup max={50}>
              {allCustomers.map(({ customer }) => {
                return (
                  <RenderAvatar
                    key={`avatar-${apv_id}-${customer.cust_id}`}
                    src={customer.cust_photo?.ast_url}
                    variant="circular"
                    sx={{ width: 25, height: 25 }}
                    fallbackSize={15}
                  />
                );
              })}
            </AvatarGroup>
          </Stack>

          <Typography variant="caption" color="textSecondary" mt={4}>
            {format(new Date(apv_created_at), "dd/MM/yyyy", {
              locale: ptBR,
            })}
          </Typography>
        </Stack>
      </Card>
    );
  };

  const KanbanColumn: React.FC<{ title: string; cards: KanbanCardData[] }> = ({ title, cards }) => (
    <Stack
      bgcolor={theme.palette.divider}
      p={2}
      borderRadius={1}
      flexGrow={1} // Isso garante que todas as colunas tenham a mesma altura
    >
      <Stack
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
        mb={2}
      >
        <Typography variant="body1" fontWeight={"bold"} sx={{ mb: 2, textAlign: "center" }}>
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          ({cards.length})
        </Typography>
      </Stack>
      <Stack spacing={2}>
        {cards.map((card) => (
          <KanbanCard key={`card-${card.apv_id}`} {...card} />
        ))}
      </Stack>
      {cards.length === 0 && (
        <Box textAlign="center">
          <Typography variant="body2" color="textSecondary">
            Nenhum item nesta coluna
          </Typography>
        </Box>
      )}
    </Stack>
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="start"
      bgcolor={theme.palette.background.paper}
      alignItems="stretch"
    >
      {columns
        .sort((a, b) => a.stp_order - b.stp_order)
        .map((column) => (
          <Grid
            item
            xs={3}
            key={column.stp_id}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <KanbanColumn
              title={column.stp_label}
              cards={kanbanData.filter(
                (card) => card.apv_status.statusType.stp_type === column.stp_type,
              )}
            />
          </Grid>
        ))}
    </Grid>
  );
};
