import type { FC } from "react";
import { useCallback } from "react";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { LockOutlined, NotificationImportantOutlined } from "@mui/icons-material";
import { Colors, User01 } from "@untitled-ui/icons-react";
import { useRouter } from "@tanstack/react-router";
import { useSelector } from "react-redux";
import { SettingsDrawer } from "../../../components/settings/settings-drawer";
import { useOrganization } from "../../../contexts/organization/OrganizationContext";
import { SettingsContextType, SettingsConsumer } from "../../../contexts/settings";
import { authSlice, signOut } from "../../../features/auth/authSlice";
import { Issuer } from "../../../utils/auth";
import { paths } from "src/paths";
import { RouterPaths } from "src/main";
import { RouterLink } from "src/components/router-link";
import { useAppDispatch } from "src/app/hooks";

interface AccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}

interface IOptionsMenu {
  title: string;
  path: RouterPaths;
  icon: JSX.Element;
}

const options: IOptionsMenu[] = [
  {
    title: "Meu perfil",
    path: "/conta/geral",
    icon: (
      <SvgIcon fontSize="small">
        <User01 />
      </SvgIcon>
    ),
  },
  {
    title: "Notificações",
    path: "/notificacoes",
    icon: (
      <SvgIcon fontSize="small">
        <NotificationImportantOutlined />
      </SvgIcon>
    ),
  },
  {
    title: "Segurança",
    path: "/conta/seguranca",
    icon: (
      <SvgIcon fontSize="small">
        <LockOutlined />
      </SvgIcon>
    ),
  },
];

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { clearOrganizationContext } = useOrganization();
  const { anchorEl, onClose, open, ...other } = props;
  const router = useRouter();
  // const auth = useAuth();
  const supabaseUser = useSelector(authSlice.selectors.selectSupabaseUser);
  const user = useSelector(authSlice.selectors.selectUser);
  const dispatch = useAppDispatch();

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      onClose?.();
      dispatch(signOut());
      clearOrganizationContext();
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong!");
    }
  }, [clearOrganizationContext, dispatch, onClose]);

  function handleDrawerOpen(settings: SettingsContextType) {
    settings?.handleDrawerOpen();
    onClose?.();
  }

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 200 } }}
      {...other}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="body1">{user?.usu_name}</Typography>
        <Typography color="text.secondary" variant="body2">
          {user?.usu_email}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 1 }}>
        {options.map(({ icon, path, title }) => (
          <ListItemButton
            key={title}
            component={RouterLink}
            to={path}
            onClick={onClose}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">{icon}</SvgIcon>
            </ListItemIcon>
            <ListItemText primary={<Typography variant="body1">{title}</Typography>} />
          </ListItemButton>
        ))}
      </Box>
      <Divider />
      <Box sx={{ p: 1 }}>
        <SettingsConsumer>
          {(settings) => (
            <>
              <SettingsDrawer
                canReset={settings.isCustom}
                onClose={settings.handleDrawerClose}
                onReset={settings.handleReset}
                onUpdate={settings.handleUpdate}
                open={settings.openDrawer}
                values={{
                  colorPreset: settings.colorPreset,
                  contrast: settings.contrast,
                  direction: settings.direction,
                  paletteMode: settings.paletteMode,
                  responsiveFontSizes: settings.responsiveFontSizes,
                  stretch: settings.stretch,
                  layout: settings.layout,
                  navColor: settings.navColor,
                }}
              />
              <ListItemButton
                onClick={() => handleDrawerOpen(settings)}
                sx={{
                  borderRadius: 1,
                  px: 1,
                  py: 0.5,
                }}
              >
                <ListItemIcon>
                  <SvgIcon fontSize="small">
                    <Colors />
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText primary={<Typography variant="body1">Layout</Typography>} />
              </ListItemButton>
            </>
          )}
        </SettingsConsumer>
      </Box>
      <Divider sx={{ my: "0 !important" }} />
      <Box
        sx={{
          display: "flex",
          p: 1,
          justifyContent: "center",
        }}
      >
        <Button color="inherit" onClick={handleLogout} size="small">
          Sair
        </Button>
      </Box>
    </Popover>
  );
};
