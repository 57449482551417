import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";
import { FC, ReactNode } from "react";
import { useSettings } from "../hooks/use-settings";
import { HorizontalLayout } from "../layouts/dashboard/horizontal-layout";
import { VerticalLayout } from "../layouts/dashboard/vertical-layout";
import { OnboardingGuard } from "src/guards/onboarding-guard";
import { BillingGuard } from "src/guards/billing-guard";
import { OrganizationGuard } from "src/contexts/organization/OrganizationContext";
import { AuthGuard } from "src/guards/auth-guard";

export const Route = createFileRoute("/_authenticated")({
  component: () => (
    <Layout>
      <Outlet />
    </Layout>
  ),
  beforeLoad: async ({ context, location, ...others }) => {
    const {
      data: { session },
    } = await context.auth.getSession();

    if (!session) {
      throw redirect({
        to: "/auth/login",
        search: { returnTo: location.href },
      });
    } else {
      const urlParams = new URLSearchParams(location.search);
      const returnTo = urlParams.get("returnTo");

      if (returnTo) {
        throw redirect({
          to: returnTo,
        });
      }
    }
  },
});

interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  const settings = useSettings();

  const Wrapper: FC<{ children: ReactNode }> = ({ children }) => (
    <>
      <AuthGuard>
        <OrganizationGuard>
          <BillingGuard>
            <OnboardingGuard>{children}</OnboardingGuard>
          </BillingGuard>
        </OrganizationGuard>
      </AuthGuard>
    </>
  );

  if (settings.layout === "horizontal") {
    return (
      <Wrapper>
        <HorizontalLayout navColor={settings.navColor}>{children}</HorizontalLayout>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <VerticalLayout navColor={settings.navColor}>{children}</VerticalLayout>
    </Wrapper>
  );
};
