import { createFileRoute, useRouter } from "@tanstack/react-router";
import * as Yup from "yup";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link } from "../../utils/router-link";
import { Link as MuiLink } from "@mui/material";
import React, { useCallback } from "react";
import { LoadingButton } from "../../components/loading-button";
import { RouterLink } from "src/components/router-link";
import { useAppDispatch } from "src/app/hooks";
import {
  authStateChanged,
  handleAuthSignIn,
  signInWithEmailAndPassword,
  signInWithGoogle,
} from "src/features/auth/authSlice";
import {
  organizationThunks,
  setSelectedOrganization,
} from "src/features/organization/organizationSlice";
import toast from "react-hot-toast";

interface IQueryParams {
  username?: string;
  returnTo?: string;
}

export const Route = createFileRoute("/auth/login")({
  component: () => <Page />,
  validateSearch: (search: Record<string, unknown>): IQueryParams => {
    const username = search.username as string | undefined;
    const returnTo = search.returnTo as string | undefined;
    return { username, returnTo };
  },
});

interface Values {
  email: string;
  password: string;
  submit: null;
}

const initialValues: Values = {
  email: "",
  password: "",
  submit: null,
};

const validationSchema = Yup.object({
  email: Yup.string().email("Deve ser um email válido").max(255).required("Email é obrigatório"),
  password: Yup.string().max(255).required("Senha é obrigatória"),
});

const Page = () => {
  const router = useRouter();
  const { returnTo, username } = Route.useSearch();
  const dispatch = useAppDispatch();

  async function _signInWithEmailAndPassword(email: string, password: string) {
    try {
      await dispatch(signInWithEmailAndPassword({ email, password }));
    } catch (error) {
      toast.error((error as Error)?.message ?? "Erro ao fazer login");
    }
  }

  const _signInWithGoogle = React.useCallback(async () => {
    await dispatch(signInWithGoogle());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      email: username || initialValues.email,
    },
    validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        await _signInWithEmailAndPassword(values.email, values.password);
      } catch (err) {
        console.error(err);

        helpers.setStatus({ success: false });
        if (err instanceof Error) {
          helpers.setErrors({ submit: err.message });
        } else {
          helpers.setErrors({ submit: "An unknown error occurred" });
        }
        helpers.setSubmitting(false);
      }
    },
  });

  const handleGoogleClick = useCallback(async (): Promise<void> => {
    _signInWithGoogle();
  }, [_signInWithGoogle]);

  return (
    <>
      <div>
        <Card elevation={16}>
          <CardContent>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                <Button
                  fullWidth
                  onClick={handleGoogleClick}
                  size="large"
                  sx={{
                    backgroundColor: "common.white",
                    color: "common.black",
                    "&:hover": {
                      backgroundColor: "common.white",
                      color: "common.black",
                    },
                  }}
                  variant="contained"
                >
                  <Box
                    alt="Google"
                    component="img"
                    src="/assets/auth/logo-google.svg"
                    sx={{ mr: 1 }}
                  />
                  Google
                </Button>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    mt: 2,
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Divider orientation="horizontal" />
                  </Box>
                  <Typography color="text.secondary" sx={{ m: 2 }} variant="body1">
                    OU
                  </Typography>
                  <Box sx={{ flexGrow: 1 }}>
                    <Divider orientation="horizontal" />
                  </Box>
                </Box>
              </Box>
              <Stack spacing={3}>
                <TextField
                  error={!!(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  label="Endereço de Email"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.email}
                />
                <TextField
                  error={!!(formik.touched.password && formik.errors.password)}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  label="Senha"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.password}
                />
              </Stack>
              {formik.errors.submit && (
                <FormHelperText error sx={{ mt: 3 }}>
                  {formik.errors.submit as string}
                </FormHelperText>
              )}
              <Box sx={{ mt: 2 }}>
                <LoadingButton
                  loading={formik.isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Entrar
                </LoadingButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  mt: 3,
                }}
              >
                <Link
                  component={RouterLink}
                  to={"/auth/recuperar-senha"}
                  underline="hover"
                  variant="body2"
                >
                  Esqueceu a senha?
                </Link>

                <Link component={RouterLink} to="/auth/registrar" underline="hover" variant="body2">
                  Criar nova conta
                </Link>
              </Box>
              <Box sx={{ mt: 5 }} display={"flex"} justifyContent={"center"}>
                <Typography color="text.secondary" variant="caption">
                  Ao entrar, você concorda com os{" "}
                  <MuiLink
                    color="primary"
                    href={"https://www.thumbz.app/termos-e-condicoes"}
                    target="_blank"
                    underline="hover"
                  >
                    Termos de Serviço
                  </MuiLink>
                  {" e "}
                  <MuiLink
                    color="primary"
                    href="https://www.thumbz.app/politica-de-privacidade"
                    target="_blank"
                    underline="hover"
                  >
                    Política de Privacidade
                  </MuiLink>
                </Typography>
              </Box>
            </form>
          </CardContent>
        </Card>
      </div>
    </>
  );
};
